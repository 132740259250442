import React from 'react'
import { MDXTag } from '@mdx-js/tag'




export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}><div style={{width: 'auto', pageBreakInside: 'avoid', WebkitColumnBreakInside: 'avoid', breakInside: 'avoid', maxWidth: 350, margin: 'auto'}}><div style={{overflow: 'hidden', paddingBottom: '56.25%', position: 'relative', height: 0}}><iframe src="https://www.fulcrum.org/embed?hdl=2027/fulcrum.1v53jx99p" title="Test%20Title" style={{overflow: 'hidden', borderWidth: 0, left: 0, top: 0, width: '100%', height: '100%', position: 'absolute'}} /></div></div>
</MDXTag>

export const _frontmatter = {"templateKey":"content-pages","title":"How is writing a social activity?","key":"how-is-writing-a-social-activity","parentKey":"writing-is-social"};

  